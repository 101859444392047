import React from 'react';

function About() {
  return (
    <section className="section">
      <h2>Sobre Mí</h2>
      <p>Técnico informático con más de 3 años de experiencia en la industria tecnológica, especializado en soporte técnico, virtualización y desarrollo web con Python y JavaScript.</p>
    </section>
  );
}

export default About;
