import React from 'react';

function Education() {
  return (
    <section className="section">
      <h2>Educación</h2>
      <ul>
        <li><strong>Universidad Complutense</strong> - Ingeniería de Materiales</li>
        <li><strong>Universidad de Valencia VIU</strong> - Grado en Ingeniería Informática</li>
        <li><strong>Master en Machine Learning con Python</strong> (2022)</li>
      </ul>
    </section>
  );
}

export default Education;
