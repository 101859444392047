import React from 'react';

function Experience() {
  return (
    <section className="section">
      <h2>Experiencia Laboral</h2>
      <ul>
        <li><strong>Alten</strong> - Administrador de Virtualización (Feb 2024)</li>
        <li><strong>AvanzArts</strong> - Programador Analista (Marzo 2018 - Febrero 2021)</li>
        <li><strong>Policía Municipal de Madrid</strong> - Técnico Informático (Sep 2021 - Jul 2023)</li>
      </ul>
    </section>
  );
}

export default Experience;
