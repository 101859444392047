import React from 'react';
import { FaPython, FaJs, FaGit, FaDatabase, FaTools } from 'react-icons/fa';

function Skills() {
  return (
    <section className="section">
      <h2>Habilidades</h2>
      <ul>
        <li><FaPython className="icon" /> Python, JavaScript</li>
        <li><FaGit className="icon" /> Git, SQL, PL/SQL</li>
        <li><FaTools className="icon" /> Soporte Software y Hardware, VMware</li>
        <li><FaDatabase className="icon" /> Nagios, ServiceNow, GLPI</li>
      </ul>
    </section>
  );
}

export default Skills;
