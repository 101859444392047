import React from 'react';
import { FaPhone, FaEnvelope, FaLinkedin } from 'react-icons/fa';

function Contact() {
  return (
    <section className="section">
      <h2>Contacto</h2>
      <ul>
        <li><FaPhone className="icon" /> +34 634 02 44 59</li>
        <li><FaEnvelope className="icon" /> jorgetley98@gmail.com</li>
        <li><FaLinkedin className="icon" /> <a href="<https://www.linkedin.com/in/jorge-enrique-tejero-leyva-7bb265123/>">LinkedIn</a></li>
      </ul>
    </section>
  );
}

export default Contact;
